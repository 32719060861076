import React from 'react';

const MedicalAgent = () => {
  return (
    <div>
      <h1>Medical Assistance</h1>
      <iframe
        src="https://localhost:3000"
        style={{ width: '100%', height: '80vh', border: 'none' }}
        title="Medical Assistance"
      />
    </div>
  );
};

export default MedicalAgent;


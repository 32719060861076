import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import MedicalAssistance from './components/MedicalAssistance';
import LegalAssistance from './components/LegalAssistance';
import PayrollAssistance from './components/PayrollAssistance';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Legal from './pages/Legal';
import TermsOfUse from './pages/TermsOfUse';
import './i18n';
import './App.css';

const App = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('nl');

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  return (
    <Router>
      <Header language={language} setLanguage={changeLanguage} />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/medical-assitant" element={<MedicalAssistance />} />
        <Route path="/legal-assistant" element={<LegalAssistance />} />
        <Route path="/payroll-assistant" element={<PayrollAssistance />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
      </Routes>
    </Router>
  );
};

export default App;

